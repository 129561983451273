<template>
  <div id="js-loader" class="loader">
    <div class="loader-animation"></div>
  </div>
  <div class="home">
    <div id="all_wrap">
		<div class="page_header">
			<div class="mv_cloud">
				<div class="right_area">
					<div class="img04"><img src="@/assets/front_component/images/assets/back02.png" alt="雲"></div>
				</div>
				<div class="left_area">
					<div class="img01"><img src="@/assets/front_component/images/assets/back01.png" alt="雲"></div>
				</div>
			</div>
		</div>
		<main>
			<div class="section_all_wrap">
				<div class="content_in_wrap">
					<div class="content">
						<p>
              アプリケーションエラー
              処理中に予期せぬエラーがありました。
              再度お試しください。
              症状が改善されない場合は下記エラーログをダウンロードいただき、
              システム管理者にご連絡ください。
            //	(エラーログ)←エラーログファイル
              (戻る)←SuperReload
            </p>
					</div>
				</div>
				<div class="kusa_outline">
					<div class="kusa_area">
						<div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
						<div class="deco_wrap02"><img src="@/assets/front_component/images/assets/kusa02.png" alt="草"></div>
						<div class="deco_wrap03"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
						<div class="deco_wrap04"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
						<div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
						<div class="deco_wrap06"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
						<div class="deco_wrap07"><img src="@/assets/front_component/images/assets/kusa05.png" alt="草"></div>
						<div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
						<div class="deco_wrap09"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
						<div class="deco_wrap10"><img src="@/assets/front_component/images/assets/kusa07.png" alt="草"></div>
						<div class="deco_wrap11"><img src="@/assets/front_component/images/assets/kusa06.png" alt="草"></div>
						<div class="deco_wrap12"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
						<div class="deco_wrap13"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
						<div class="deco_wrap14"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
						<div class="deco_wrap15"><img src="@/assets/front_component/images/assets/kusa04.png" alt="草"></div>
						<div class="deco_wrap16"><img src="@/assets/front_component/images/assets/kusa03.png" alt="草"></div>
						<div class="deco_wrap17"><img src="@/assets/front_component/images/assets/kusa01.png" alt="草"></div>
					</div>
				</div>
				<div class="deco_outline">
					<div class="deco_area">
						<div class="deco_wrap01"><img src="@/assets/front_component/images/assets/kikyu01.png" alt="気球"></div>
						<div class="deco_wrap02">
							<img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
							<img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
							<img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
						</div>
						<div class="deco_wrap03">
							<img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
							<img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
							<img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
						</div>
						<div class="deco_wrap04"><img src="@/assets/front_component/images/assets/heri01.png" alt="ヘリコプター"></div>
						<div class="deco_wrap05"><img src="@/assets/front_component/images/assets/kikyu02.png" alt="気球"></div>
						<div class="deco_wrap06">
							<img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
							<img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
							<img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
						</div>
						<div class="deco_wrap07">
							<img src="@/assets/front_component/images/assets/hana01.png" alt="花" class="hana01">
							<img src="@/assets/front_component/images/assets/hana02.png" alt="花" class="hana02">
							<img src="@/assets/front_component/images/assets/hana03.png" alt="花" class="hana03">
						</div>
						<div class="deco_wrap08"><img src="@/assets/front_component/images/assets/kikyu03.png" alt="気球"></div>
					</div>
				</div>
			</div>
		</main>
		<div class="menu_wrap">
			<div class="menu_icon">
				<img src="@/assets/front_component/images/assets/menu01.png" alt="メニュー">
			</div>
		</div>
	</div>
  </div>
</template>

<script>
export default {
  name: "error",
  data() {
    return {
    };
  },
  components: {},
  created() {
  },
    mounted() {
            document.body.className = 'page_control';
        },
  methods: {

  },
};
</script>